import {Injectable} from '@angular/core';
import {AbstractService} from '../../services/abstract-service.service';
import {HttpClient} from '@angular/common/http';
import {ADMIN_CONFIG} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {DiscountHistoryModel} from '../ek-models/discountHistory.model';
import {Page} from '../../_base/crud/models/page';

@Injectable({
    providedIn: 'root'
})
export class DiscountService extends AbstractService<DiscountHistoryModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}discount-history`;
    }

    getDiscountHistoryByFolderId(folderId: number): Observable<Page<DiscountHistoryModel>> {
        const url = `${this.url}/with-folder/${folderId}`;
        return this.http.get<Page<DiscountHistoryModel>>(url);
    }
}