import {Role} from "../_models/role.model";

export class RolesTable {
	public static roles: Role[] = [
        new Role('ROLE_SUPERADMIN', 'Super Administrateur', [],false),
        new Role('ROLE_ADMIN', 'Administrateur',  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],true),
        new Role('ROLE_POS_EK', 'POS (Point de vente)', [],false),
        new Role('ROLE_CREDIT_ANALYST_EK', 'Credit Analyst', [],false),
        new Role('ROLE_BANKER_EK', 'Banquier', [],false),
        new Role('ROLE_BANK_API', 'Banque API', [],false),
        new Role('ROLE_COMMERCIAL_REGION_MANAGER', 'Responsable Commercial Régional', [],false),
        new Role('ROLE_GUEST_ADMIN', 'Administrateur invité', [], false),
        new Role('ROLE_CONFORMITY_MANAGER', 'Conformité', [], false),
        new Role('ROLE_COMMERCIAL_POS_EK', 'Commercial', [], false),
    ];
}
