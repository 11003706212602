import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {
    selectClientsInStore, selectClientsOrdersInStore,
    selectClientsPageLoading,
    selectClientsShowInitWaitingMessage
} from "../ek-selectors/client.selector";

export class ClientsDatasource extends BaseDataSource {
    data: any[] = [];
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectClientsPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectClientsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectClientsInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
        this.store.pipe(
            select(selectClientsOrdersInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }

}
